import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../common/Layout";
import styles from "../../css/thepeak.module.css";
import logo from "../../images/on-going-projects/the-peak/logo-01.png";
import {
  SectionCon,
  Specifications,
  GallerySection,
  FloorPlan,
} from "./Project";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { VideoBtn } from "./BannerSection";
import bannerImg from "../../images/on-going-projects/the-peak/video-poster.png";
import Leaf1Img from "../../images/on-going-projects/the-peak/leaf1-01.png";
import Leaf2Img from "../../images/on-going-projects/the-peak/leaf2-01.png";
import Leaf3Img from "../../images/on-going-projects/the-peak/leaf3-01.png";
import ProjectDetails from "./ProjectDetails";

const useThePeakData = () =>
  useStaticQuery(graphql`
    query {
      green: file(
        relativePath: {
          eq: "on-going-projects/crown-residence/green-open-spaces/1.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ongoingJson(slug: { eq: "the-peak" }) {
        banner {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        logo {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        slug
        rera
        title
        tagline
        brouchureUrl
        video {
          type
          link
        }
        sections {
          title
          synopsis
          specification {
            a
            q
          }
          floor_plan {
            title
            pricing
            rera
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          highlights
          features {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            points
          }
          gMapUrl
          gallery {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          project_updates {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            videos {
              type
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
          walkthrough {
            title
            urls
          }
        }
      }
    }
  `);

const ThePeak = () => {
  const { ongoingJson } = useThePeakData();
  const project = ongoingJson;
  const sections = project.sections || [];
  const current = sections[0] || {};

  return (
    <Layout>
      <Helmet>
        <title>{project.title}</title>
        <meta name="description" content={project.tagline} />
        <meta property="og:type" content="website" />
        {project.banner && project.banner.childImageSharp && (
          <meta
            property="og:image"
            content={project.banner.childImageSharp.fluid.src}
          />
        )}
      </Helmet>

      <div className={styles.thePeakBannerSection}>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 mb-md-0"
              style={{ position: "relative", width: "100%", zIndex: 10 }}
            >
              <div style={{ top: "0", bottom: "0", left: "0", right: "0" }}>
                <div
                  className={`d-flex justify-content-center ${styles.thePeakBannerContent} mb-3`}
                >
                  <img className="mb-3" src={logo} width="250" alt="logo" />
                </div>
                <div className="row justify-content-end">
                  <div className="col-md-8" style={{ zIndex: 10 }}>
                    <a
                      href={project.brouchureUrl}
                      className={`btn ${styles.thePeakBroBtn} mb-1 d-block`}
                      download="the-peak"
                      target="_blank"
                      rel="noreferrer"
                    >
                      E-Brochure Download
                    </a>
                    <div className="text-right" style={{ fontSize: "10px" }}>
                      {project.rera}
                    </div>
                  </div>
                </div>
                <div className={styles.thePeakLeaf2}>
                  <img alt="leaf" src={Leaf2Img} />
                </div>
              </div>
            </div>
            <div
              className="col-md-6 mb-3 mb-md-0"
              style={{ position: "relative", width: "100%", zIndex: 10 }}
            >
              <div
                className={`${styles.thePeakVideo}`}
                style={{ top: "0", bottom: "0", left: "0", right: "0" }}
              >
                <img alt="banner" src={bannerImg} />
                <div
                  className="abs-full d-flex justify-content-end align-items-start pr-5 pt-4"
                  style={{ zIndex: 10 }}
                >
                  <VideoBtn video={project.video} />
                </div>
              </div>
              <div className={styles.thePeakLeaf1}>
                <img src={Leaf1Img} alt="leafimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectDetails project_updates={current.project_updates} />
      <SectionCon>
        <div className="pb-4">
          <a
            rel="noopener noreferrer"
            href={`#synopsis`}
            id={"synopsis"}
            aria-hidden="true"
            className="resident-scroll-link"
          >
            {" "}
          </a>
          <h5 className="residence-section-title">Project Synopsis</h5>
          <div className={styles.thePeakText}>
            <div className="row">
              <div className="col-md-6 pl-5 w-50">
                <h1
                  className={`badge badge-secondary text-white h2  ${styles.thePeakText} ${styles.thePeakLocationContentTitleSub}`}
                >
                  FIRST-OF-ITS-KIND
                </h1>
                <div className="h1" style={{ fontSize: "85px" }}>
                  ICONIC HIGH-RISE
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="row p-2"
                  style={{ boxShadow: "-2px 3px 1px 2px #5e3e53" }}
                >
                  <div
                    className={`col-md-6 py-2 ${styles.thePeakSynopsisRightContent}`}
                  >
                    <p
                      className="residence-section-desc align-items-center"
                      style={{ fontSize: "18px" }}
                    >
                      A twenty storey imposing development looms large to
                      accomodate multiple iconic high-rise towers. It proudly
                      showcases deft minimalistic design that refuses to conform
                      to the routine.
                    </p>
                    <p
                      className="residence-section-desc align-items-center m-0"
                      style={{ fontSize: "18px" }}
                    >
                      This upscale habitat relentlessly pushes the boundaries of
                      elite living standards and stands as a shining epitome of
                      grace.
                    </p>
                  </div>
                  <div className="col-md-6 mt-2 mt-md-0">
                    <img
                      alt="banner"
                      src={project.banner.childImageSharp.fluid.src}
                      className={styles.bannerImg}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionCon>
      <SectionCon>
        <a
          href={`#location`}
          id={"location"}
          aria-hidden="true"
          className="resident-scroll-link"
        >
          {" "}
        </a>
        <h5 className="residence-section-title">Location</h5>
        <div className={styles.thePeakText}>
          <div className="row">
            <div className={`col-md-6 `}>
              <div className={styles.thePeakLeaf3}>
                <img src={Leaf3Img} alt="leaf" />
              </div>
              <div className="d-flex align-items-center w-75">
                <h2 className={styles.thePeakLocationContentTitle}>
                  11TH AVENUE ASHOK NAGAR
                </h2>
                <div className="thePeakLocationContentTitleSub w-25 pl-3">
                  <div
                    className={`badge badge-secondary ${styles.thePeakLocationContentTitleSub}`}
                  >
                    FINNALY
                  </div>
                  <div
                    className={`badge badge-secondary ${styles.thePeakLocationContentTitleSub}`}
                  >
                    GETS
                  </div>
                  <div
                    className={`badge badge-secondary ${styles.thePeakLocationContentTitleSub}`}
                  >
                    ITS
                  </div>
                  <div
                    className={`badge badge-secondary ${styles.thePeakLocationContentTitleSub}`}
                  >
                    MOMENTOUS
                  </div>
                  <div
                    className={`badge badge-secondary ${styles.thePeakLocationContentTitleSub}`}
                  >
                    BENCHMARK
                  </div>
                </div>
              </div>
              <div className={styles.thePeakLocationContent}>
                <p>
                  Imagine a popular residential site, yearning patiently for
                  decades, profoundly evolve into something grander and attain
                  higher ranking honours. That's precisely what has materialised
                  with the pioneering intervention of Brand Baashyaam.
                </p>
                <p>
                  This sizeable, noteworthy property right on{" "}
                  <b>11th Avenue, Ashok Nagar</b> is an integral part of an
                  exemplar neighbourhood. It now finally gets the long overdue
                  attention and fitting tribute that it richly deserves.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row align-items-end ">
                <div className="col-md-8 col-8">
                  <h6 className={styles.thePeakText}>
                    MIG, No120, 11th Avenue,<br></br> Ashok Nagar, Chennai -
                    600083
                  </h6>
                </div>
                <div className="col-md-4 col-4 mb-2">
                  <div className="row justify-content-md-center">
                    <img
                      className=""
                      src={project.logo.childImageSharp.fluid.src}
                      height="100"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <iframe
                title="google-maps"
                src={current.gMapUrl}
                frameBorder={0}
                height={200}
                style={{ border: 0, width: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </SectionCon>
      <SectionCon>
        <Specifications specification={current.specification} />
        <FloorPlan floorPlan={current.floor_plan} />
        <GallerySection gallery={current.gallery} />
        <GallerySection
          title="Project Status"
          gallery={current.project_updates}
        />
      </SectionCon>
    </Layout>
  );
};

export const PanaromaImages = ({ images = [] }) => {
  return images.map(({ node }) => (
    <div className="col-md-3 col-6 mb-2" key={node.id}>
      <Link to={`/360-image-viewer?image=${node.childImageSharp.original.src}`}>
        <Img fluid={node.childImageSharp.fluid} alt="" />
      </Link>
    </div>
  ));
};

export default ThePeak;
